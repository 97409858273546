import React from 'react';
import BaseStyles from './BaseStyles.js';

class Template extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <BaseStyles />
        {/* <Container> */}
        {children}
        {/* </Container> */}
      </>
    );
  }
}

export default Template;
